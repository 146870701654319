<template>
  <div class="app-container">
    <el-date-picker size="small" v-model="selectDate" type="datetimerange" :picker-options="pickerOptions"
      :range-separator="$t('to')" :start-placeholder="$t('search_from')" :end-placeholder="$t('search_to')"
      align="right" @change="handleDateChange">
    </el-date-picker>
    <el-input @change="phoneNumberChange()" v-model="phoneNumber" :placeholder="$t('phone_number')"
      style="width: 150px; margin-left: 20px;" size="small" clearable maxlength="30"></el-input>
    <div class="chart-container">
      <div id="barChart"></div>
      <div id="pieChart"></div>
    </div>
    <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
      <el-form-item prop="viewerTitle">
        <el-input v-model="dataForm.viewerTitle" :placeholder="$t('model_viewer_name')" size="small" clearable
          maxlength="30"></el-input>
      </el-form-item>
      <el-form-item prop="visitorPhone">
        <el-input v-model="dataForm.visitorPhone" :placeholder="$t('phone_number')" size="small" clearable
          maxlength="30"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">{{
          $t('search')
        }}</el-button>
        <el-button @click="reset('dataForm')" size="small">{{
          $t('clear')
        }}</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border size="small" v-loading="dataListLoading" style="width: 100%">
      <!-- <el-table-column prop="viewerTitle" header-align="center" align="center" :label="$t('model_viewer_name')">
      </el-table-column> -->
      <el-table-column prop="visitor" header-align="center" align="center" label="访问人">
      </el-table-column>
      <el-table-column prop="visitorPhone" header-align="center" align="center" label="访问人手机号">
      </el-table-column>
      <el-table-column prop="ipRegion" header-align="center" align="center" label="访问人IP城市">
      </el-table-column>
      <el-table-column prop="visitorCompany" header-align="center" align="center" label="访问人公司名称">
      </el-table-column>
      <el-table-column prop="createTime" header-align="center" align="center" label="访问时间">
      </el-table-column>
      <!-- <el-table-column prop="clickNum" header-align="center" align="center" label="访问次数">
      </el-table-column> -->
      <el-table-column prop="viewerTitle" header-align="center" align="center" label="访问模型">
      </el-table-column>
    </el-table>
    <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
      :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { queryModelViewerLogList, getModelViewerCount } from '@/api/modules/modelViewerManagement'
export default {
  data() {
    return {
      phoneNumber: '',
      selectDate: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dataCountList: [],
      pieDataList: [],
      dataForm: {
        viewerTitle: '',
        visitorPhone: ''
      },
      industries: [
        { value: '0', label: '交通运输' },
        { value: '1', label: '建筑建材' },
        { value: '2', label: '通用工业' },
        { value: '3', label: '绿色生活' },
        { value: '4', label: '其他' }
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false
    }
  },
  computed: {
    link() {
      const currentURL = window.location.href
      const link =
        currentURL.replace(this.$route.fullPath, '') +
        `/modelViewerScreen?brandId=${sessionStorage.getItem('BrandInfo')}`
      return link
    }
  },
  mounted() {
    this.getCountData()
    this.barChartInit();
    this.pieChartInit();
  },
  components: {
  },
  created() {
    this.getDataList()
  },
  methods: {
    handleDateChange() {
      this.getCountData()
    },
    phoneNumberChange() {
      this.getCountData()
    },
    // 柱状图 
    barChartInit() {
      // 初始化 ECharts 实例
      var myChart = echarts.init(document.getElementById('barChart'));
      var option;
      option = {
        dataset: {
          source: this.dataCountList
        },
        grid: { containLabel: true },
        xAxis: { name: 'amount' },
        yAxis: { type: 'category' },
        visualMap: {
          orient: 'horizontal',
          left: 'center',
          min: 10,
          max: 100,
          text: ['High Score', 'Low Score'],
          // Map the score column to color
          dimension: 0,
          inRange: {
            color: ['#65B581', '#FFCE34', '#FD665F']
          }
        },
        series: [
          {
            type: 'bar',
            encode: {
              // Map the "amount" column to X axis.
              x: 'amount',
              // Map the "product" column to Y axis
              y: 'product'
            },
            label: {
              show: true,   // 使用数据值显示标签
            }
          }
        ]
      };


      // 使用指定的配置项和数据显示图表
      myChart.setOption(option);
    },
    pieChartInit() {
      // 初始化 ECharts 实例
      var myChart = echarts.init(document.getElementById('pieChart'));
      var option;

      option = {
        title: {
          text: this.$t('Visit_top'),
          subtext: 'From 3D-Viewer',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: this.pieDataList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      // 使用指定的配置项和数据显示图表
      myChart.setOption(option);
    },

    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getDataList()
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields()
      this.restPage()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        viewerTitle: this.dataForm.viewerTitle,
        visitorPhone: this.dataForm.visitorPhone,
      }
      queryModelViewerLogList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.ListData.list
          this.totalPage = data.ListData.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    getCountData() {
      if (this.selectDate && this.selectDate.length === 2) {
        const startDate = this.formatDate(this.selectDate[0]);  // 格式化开始日期
        const endDate = this.formatDate(this.selectDate[1]);    // 格式化结束日期

        console.log('获取数据', startDate, endDate);

        var params = {
          startDate: startDate,
          endDate: endDate,
          phoneNumber: this.phoneNumber
        }
      } else {
        params = {
          startDate: '',
          endDate: '',
          phoneNumber: this.phoneNumber
        }
      }

      getModelViewerCount(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataCountList = data.data.barData
          this.pieDataList = data.data.pieData
          this.barChartInit();
          this.pieChartInit();
        }
      })
    },
    // 格式化日期为 "yyyy-MM-dd HH:mm:ss" 格式
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 确保月份是两位数
      const day = String(date.getDate()).padStart(2, '0'); // 确保日期是两位数
      const hours = String(date.getHours()).padStart(2, '0'); // 确保小时是两位数
      const minutes = String(date.getMinutes()).padStart(2, '0'); // 确保分钟是两位数
      const seconds = String(date.getSeconds()).padStart(2, '0'); // 确保秒数是两位数

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },

    disableHandle(id, status) {
      updateStatus({ viewerId: id, status: status == '0' ? '1' : '0' }).then(
        ({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
            this.$nextTick(() => {
              this.restPage()
            })
          } else {
            this.$message.error(data.msg)
          }
          this.dataListLoading = false
        }
      )
    },
    // 删除
    deleteHandle(id) {
      const params = {
        viewerId: id
      }

      this.$confirm(`是否确认删除公告?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    copyLink() {
      navigator.clipboard.writeText(this.link)
      this.$message({
        message: this.$t('copy_success'),
        type: 'success',
        duration: 1500
      })
    },
    openPreview(viewerId) {
      window.open(`${this.link}&viewerId=${viewerId}&type=preview`)
    }
  }
}
</script>
<style scoped>
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  /* 间距可调 */
  margin-bottom: 20px;
  /* 与表单的间距 */
}

#barChart {
  width: 700px;
  /* 根据需要调整宽度 */
  height: 500px;

}

#pieChart {
  width: 500px;
  /* 根据需要调整宽度 */
  height: 500px;
}
</style>
